<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="grid grid-flow-col auto-cols-max mt-5">
        <t-button
          class="button border-2 text-sm rounded-md mr-3"
          :variant="scenarioButtonColor"
          :disabled="!addDisabled"
          @click="goToScenariosList()"
        >
          <i class="fas fa-list"></i>
          Overview
        </t-button>
        <t-button
          class="button border-2 text-sm rounded-md mr-3"
          :variant="addButtonColor"
          :disabled="addDisabled"
          @click="onAddScenarioClick()"
        >
          <i class="fas fa-plus"></i>
          {{ $t("scenario_add") }}
        </t-button>
      </div>
      <router-view @setLoading="loading = $event" />
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";

export default {
  components: { Layout },

  data: () => ({
    loading: false,
  }),

  methods: {
    onAddScenarioClick() {
      if (this.$route.path === "/manager/scenarios") {
        this.loading = false;

        return this.$router.push({
          path: "/manager/scenarios/scenario-shifts",
        });
      }
    },

    goToScenariosList() {
      if (this.$route.path !== "/manager/scenarios") {
        this.loading = false;

        return this.$router.push({
          path: "/manager/scenarios",
        });
      }
    },
  },

  computed: {
    scenarioButtonColor() {
      return this.$route.path !== "/manager/scenarios" ? "primary" : "";
    },

    addButtonColor() {
      return this.$route.path === "/manager/scenarios" ? "primary" : "";
    },

    addDisabled() {
      return this.$route.path !== "/manager/scenarios";
    },
  },
};
</script>

<style lang="scss" scoped></style>
